import { action, computed, makeObservable, observable } from "mobx";
import { runVinClient } from "../utils/clients";
export default class RunVinStore {
  @observable vin: string = "";
  @observable loadingVhr: boolean = false;
  @observable runVinError: boolean = false;
  @observable noReportError: boolean = false;

  constructor() {
    makeObservable(this); // Necessary with mobx6 for decorators to work
  }

  @action updateVin(vin: string) {
    this.vin = vin;
  }

  @computed
  get formattedVin(): string {
    return this.vin.replace(/\W/g, "").toUpperCase();
  }

  @computed
  get isValidVin(): boolean {
    const length = this.formattedVin.length;
    return length === 16 || length === 17;
  }

  @action
  actuallyRunVin = () => {
    if (this.isValidVin) {
      this.loadingVhr = true;
      runVinClient
        .requestVhr(this.vin)
        .then((response) => {
          this.loadingVhr = false;
          const { report } = response.data;
          const newWindow = window.open("");
          if (report && newWindow) {
            const html = unescape(report);
            newWindow.document.write(html);
            newWindow.document.close();
          } else {
            this.runVinError = true;
          }
        })
        .catch((error) => {
          console.error(JSON.stringify(error));
          if (error?.response?.status === 401) {
            window.location.href = `https://${window.location.hostname}/login`;
          } else if (error?.response?.status === 422) {
            this.runVinError = false;
            this.noReportError = true;
            this.loadingVhr = false;
          } else {
            this.runVinError = true;
            this.noReportError = false;
            this.loadingVhr = false;
          }
        });
    }
  };
}
