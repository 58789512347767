import Button from "@carfax-stencils/button";
import TextInput from "@carfax-stencils/input-text";
import { SpinnerDots } from "@carfax-stencils/spinner";
import { observer } from "mobx-react";
import { Redirect } from "react-router";
import cfxDealersLogo from "../../assets/images/carfax_logo_solid.svg";
import runVinHeader from "../../assets/images/carfox-vin-background.png";
import spgiLogo from "../../assets/images/SPGI_Positive_Digital_Logo_No_Margins.svg";
import stores from "../../stores";
import { isMobileDevice } from "../../utils/etc";
import RequiresAuthentication from "../common/RequiresAuthentication";

export default observer(() => {
  document.title = "Courtesy Reports | Run Vin";
  return (
    <RequiresAuthentication rejectionContent={<Redirect to="/login" />}>
      <div className="run-vin-page">
        <header>
          <div className="top-bar">
            <div className="logos">
              <img className="carfax-logo" src={cfxDealersLogo} alt="CARFAX Logo" />
              <div className="divider" />
              <img className="ihs-logo" src={spgiLogo} alt="IHS markit Logo" />
            </div>
            <div className="top-bar--right-section">
              <p>{stores.session.displayEmail}</p>
              <Button
                children={`Sign Out`}
                theme="white"
                onClick={stores.session.logout}
                size={isMobileDevice() ? "small" : "medium"}
              />
            </div>
          </div>
        </header>
        <main>
          <img className="run-vin-header-image" src={runVinHeader} alt="CARFOX leaning" />
          <h1>Get a Vehicle History Report</h1>
          <h2>Know how a vehicle&#39;s history affects its value</h2>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              stores.runVin.actuallyRunVin();
            }}
          >
            <div className="vin-box-wrapper">
              <div className="vin-text-input-wrapper">
                <TextInput
                  placeholder="Enter VIN"
                  onChange={(e) => {
                    stores.runVin.updateVin(e.currentTarget.value);
                  }}
                  value={stores.runVin.formattedVin}
                />
              </div>

              <Button
                children={stores.runVin.loadingVhr ? <SpinnerDots darkMode /> : "Get Report"}
                theme="blue"
                disabled={!stores.runVin.isValidVin}
                className="run-vin-button"
                onClick={stores.runVin.actuallyRunVin}
              />
            </div>
            {stores.runVin.runVinError && (
              <div className="run-vin-error">
                Error running VIN. <a href="/vin">Reload page {">"}</a>
              </div>
            )}
            {stores.runVin.noReportError && (
              <div className="run-vin-error">
                No report found for vin <a href="/vin">{stores.runVin.formattedVin}</a>.
              </div>
            )}
          </form>
        </main>
        <footer>
          <div>
            <div className="footer-left-content">
              <img src={cfxDealersLogo} alt="CARFAX Logo" />
              <p>&copy; 2023 CARFAX. All rights reserved.</p>
            </div>
            <div className="footer-right-content">
              <div>You look great today, Have a nice day!</div>
            </div>
          </div>
        </footer>
      </div>
    </RequiresAuthentication>
  );
});
