import stores from "../stores";
import ApiClient from "./apiClient";

export type ResponseType = {
  report?: string;
};

export default class RunVinClient {
  private api: ApiClient;

  constructor(apiClient: ApiClient) {
    this.api = apiClient;
  }

  requestVhr = (vinToRun: string) => {
    if (stores.session.accessToken) {
      console.log("RunVin access token: " + stores.session.accessToken);
      this.api.setToken(stores.session.accessToken);
    }
    const userAgent = navigator.userAgent.toLowerCase();
    const isPhone = userAgent.includes("mobi") && !userAgent.includes("ipad");
    return this.api
      .post(`/reports`, {
        vin: vinToRun,
        isMobile: isPhone,
      })
      .then((response) => ({
        ...response,
        data: response.data as ResponseType,
      }))
      .catch((err) => {
        console.log("Failure attempting to run vhr: " + JSON.stringify(err));
        throw err;
      });
  };
}
