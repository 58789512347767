import Button from "@carfax-stencils/button";
import { observer } from "mobx-react";
import * as React from "react";
import { Redirect } from "react-router";
import carfoxImage from "../../assets/images/1image_fox@2x.png";
import cfxDealersLogo from "../../assets/images/carfax_logo_solid.svg";
import spgiLogo from "../../assets/images/SPGI_Positive_Digital_Logo_No_Margins.svg";
import * as AuthenticationService from "../../services/authentication";
import stores from "../../stores";

export default observer(() => {
  if (stores.session.isLoggedIn) {
    return <Redirect to="/vin" />;
  }
  document.title = "Courtesy Reports | Login";
  return (
    <div className="login-page">
      <header>
        <img className="carfax-logo" src={cfxDealersLogo} alt="CARFAX Logo" />
        <div className="divider" />
        <img className="ihs-logo" src={spgiLogo} alt="IHS Markit Logo" />
      </header>
      <main>
        <div className="login-box">
          <h1 className="header-text">Employee Access </h1>
          <div className="sub-header-text">
            Sign in with your employee credentials to access complimentary Vehicle History Reports.
          </div>
          <img src={carfoxImage} alt="Carfox" />
          <Button
            id="login_btn_CARFAX"
            children="CARFAX EMPLOYEE"
            theme="green"
            onClick={() => {
              window.location.assign(AuthenticationService.buildLoginUrl("carfax"));
            }}
          />
          <div id="login_btn_spacer" className="login-btn-spacer">
            or
          </div>
          <Button
            id="login_btn_IHS"
            children="S&P GLOBAL EMPLOYEE"
            theme="blue"
            onClick={() => {
              window.location.assign(AuthenticationService.buildLoginUrl("sandp"));
            }}
          />
          {stores.session.loginError && (
            <p className="error-msg">
              <strong>ERROR</strong>: {stores.session.loginError}
            </p>
          )}
        </div>
      </main>
    </div>
  );
});
