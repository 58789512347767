import Axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export default class ApiClient {
  private client: AxiosInstance;

  // tslint:disable-next-line:variable-name
  private token: string | undefined;

  constructor(baseURL: string) {
    this.client = Axios.create({
      baseURL,
    });
  }

  clearToken = () => {
    this.token = undefined;
  };

  setToken = (token: string) => {
    this.token = token;
  };

  get = (url: string, config?: AxiosRequestConfig) => this.request({ ...config, method: 'GET', url });

  delete = (url: string, config?: AxiosRequestConfig) => this.request({ ...config, method: 'DELETE', url });

  head = (url: string, config?: AxiosRequestConfig) => this.request({ ...config, method: 'HEAD', url });

  post = (url: string, data?: any, config?: AxiosRequestConfig) =>
    this.request({ ...config, method: 'POST', url, data });

  put = (url: string, data?: any, config?: AxiosRequestConfig) => this.request({ ...config, method: 'PUT', url, data });

  patch = (url: string, data?: any, config?: AxiosRequestConfig) =>
    this.request({ ...config, method: 'PATCH', url, data });

  request = (config: AxiosRequestConfig): Promise<AxiosResponse> => {
    const makeRequest = () => {
      if (this.token !== undefined) {
        return this.client.request(this.appendTokenToAxiosRequestConfig(config, this.token));
      } else {
        return this.client.request(config);
      }
    };

    return makeRequest();
  };

  private appendTokenToAxiosRequestConfig = (
    config: AxiosRequestConfig | undefined,
    token: string,
  ): AxiosRequestConfig => ({
    ...config,
    headers: {
      ...(config ? config.headers : undefined),
      Authorization: `${token}`,
    },
  });
}
