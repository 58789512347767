import { Switch } from 'react-router';
import { BrowserRouter, Redirect, Route } from 'react-router-dom';
import LoginPage from './components/pages/LoginPage';
import RunVinPage from './components/pages/RunVinPage';

const AppRoutes = () => (
  <BrowserRouter>
    <div id="browser-router">
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Route path="/vin" component={RunVinPage} />
        <Route render={() => <Redirect to="/login" />} />
      </Switch>
    </div>
  </BrowserRouter>
);

export default AppRoutes;
