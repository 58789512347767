export enum Environment {
  LOCAL = "LOCAL",
  DEVELOPMENT = "DEVELOPMENT",
  STAGING = "STAGING",
  PROD = "PROD",
}

export const getEnvironment = (): Environment => {
  if (!window || !window.location) {
    return Environment.PROD;
  }

  const hostName = window.location.hostname.toLowerCase();

  switch (hostName) {
    case "localhost":
      return Environment.LOCAL;
    case "developmentemployees.carfax.net":
      return Environment.DEVELOPMENT;
    case "stagingemployees.carfax.net":
      return Environment.STAGING;
    default:
      return Environment.PROD;
  }
};

export const getApplicationRootUrl = (): string => {
  return getEnvironment() === Environment.LOCAL
    ? `http://localhost:${window.location.port}`
    : `https://${window.location.host}`;
};
