import ApiClient from "../services/apiClient";
import RunVinClient from "../services/runVinClient";
import { Environment, getApplicationRootUrl, getEnvironment } from "../utils/environment";

const runVinRootUrl= () => {
  return getEnvironment() === Environment.LOCAL || Environment.STAGING ? `https://stagingemployees.carfax.net/api` : getApplicationRootUrl() + `/api`
} 

export const employeePortalApiClient = new ApiClient(
  runVinRootUrl(),
);

export const runVinClient = new RunVinClient(employeePortalApiClient);
