import RunVinStore from "./runVin";
import SessionStore from "./session";

const session = new SessionStore();
const runVin = new RunVinStore();

const stores = {
  session,
  runVin,
};

export default stores;
