import { Buffer } from "buffer";
import * as query from "query-string";
import { Environment, getApplicationRootUrl, getEnvironment } from "../utils/environment";

export type client = "carfax" | "sandp";
const SUCCESS_REDIRECT_URL = getApplicationRootUrl() + `/login/finish`;

const CARFAX_LOGIN_URL = `https://carfax.okta.com/oauth2/v1/authorize`;
const CARFAX_OAUTH_CLIENT_ID = "0oa1g8ow0smwyDeCp0h8";

const SANDP_LOGIN_URL = "https://spglobal.okta.com/oauth2/default/v1/authorize";
const SANDP_OAUTH_CLIENT_ID = "0oa9bttrtjKeZQIMc5d7";

export function   buildLoginUrl(clientName : client): string {
  const client_id = clientName === "carfax" ? CARFAX_OAUTH_CLIENT_ID : SANDP_OAUTH_CLIENT_ID;
  const loginUrl = clientName === "carfax" ? CARFAX_LOGIN_URL : SANDP_LOGIN_URL;
  const params = query.stringify({
    client_id: client_id,
    redirect_uri: SUCCESS_REDIRECT_URL,
    response_type: "token",
    nonce: 1,
    state: window.location.pathname,
    scope: "openid",
  });

  return loginUrl + "?" + params;
}

