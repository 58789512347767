import Cookies from "js-cookie";
import { action, computed, makeObservable, observable } from "mobx";
import * as query from "query-string";

// tslint:disable-next-line:interface-name
interface ParsedAccessToken {
  email: string;
  expiration: number;
}

class SessionStore {
  @observable
  public vhrHtml: string | undefined = undefined;
  @observable
  private parsedAccessToken: ParsedAccessToken | undefined = undefined;
  @observable
  public accessToken: string | undefined;
  @observable
  public loginError: string | undefined;
  private carfaxAccessTokenKey = "cfx_access_token";

  constructor() {
    makeObservable(this); // Necessary with mobx6 for decorators to work
    const hash = query.parse(window.location.hash);

    if (window.location.hash) {
      window.location.hash = "";

      if (hash.error) {
        this.loginError = hash.error_description as string | undefined;
      } else {
        this.parseAccessToken(hash.access_token as string | undefined);
      }

      return;
    }

    const cookieAccessToken = Cookies.get(this.carfaxAccessTokenKey);
    if (cookieAccessToken !== undefined) {
      this.parseAccessToken(cookieAccessToken);
    }
  }

  @computed
  get isLoggedIn(): boolean {
    return this.parsedAccessToken !== undefined && this.parsedAccessToken.expiration > Date.now() / 1000 - 60;
  }

  @computed
  get displayEmail(): string {
    return this.parsedAccessToken !== undefined ? this.parsedAccessToken.email : "";
  }

  @action
  public logout = () => {
    this.accessToken = undefined;
    this.parsedAccessToken = undefined;
    Cookies.remove(this.carfaxAccessTokenKey);
  };

  @action
  private parseAccessToken = (token: string | undefined) => {
    this.accessToken = token;

    if (token === undefined) {
      this.parsedAccessToken = undefined;
      return;
    }
    const parts = token.split(".");
    if (parts.length !== 3) {
      return;
    }

    try {
      const claims = JSON.parse(atob(parts[1]));
      this.parsedAccessToken = {
        email: claims.sub,
        expiration: claims.exp,
      };
      const daysLeft = (claims.exp * 1000 - Date.now()) / 1000 / 86400;
      Cookies.set(this.carfaxAccessTokenKey, token, { expires: daysLeft });
    } catch (e) {
      console.error("Something happened: " + e);
      this.logout();
    }
  };
}

export default SessionStore;
